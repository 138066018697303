import React from 'react';

import fetch from 'isomorphic-fetch';

import Header from "../Header"; // eslint-disable-line
import Playground from "../Playground"; // eslint-disable-line

import constants from '../../constants';

import './style.css';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/prop-types
      isAuthenticated: props && props.isAuthenticated,
    };
  }

    handleGraphQLFetcher = (graphQLParams) => {
      const token = window.localStorage.getItem(
          constants.TOKEN_BARRAMENTO_KEY,
      );

      if (!token) {
        return Promise.reject(new Error('Token não encontrado'));
      }

      return fetch(constants.BARRAMENTO_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-API-ORIGIN': 'AUTH0',
        },
        body: JSON.stringify(graphQLParams),
      }).then((response) => response.json());
    };

    render() {
      const {isAuthenticated} = this.state;

      return (
        <>
          <Header />
          {isAuthenticated && (
            <Playground
              isAuthenticated={isAuthenticated}
              onHandleGraphQLFetcher={this.handleGraphQLFetcher}
            />
          )}
        </>
      );
    }
}

export default Home;
