import React, {useCallback, useEffect} from 'react';
import {useAuth0, withAuthenticationRequired} from '@auth0/auth0-react';
import Home from '../Home';
import constants from '../../constants';

const Auth0 = () => {
  const {isAuthenticated, isLoading} = useAuth0();
  const {getIdTokenClaims, logout} = useAuth0();

  const getToken = useCallback(async () => {
    try {
      const claims = await getIdTokenClaims();
      const token = claims.__raw;
      window.localStorage.setItem(constants.TOKEN_BARRAMENTO_KEY, token);
    } catch (error) {
      window.localStorage.removeItem(constants.TOKEN_BARRAMENTO_KEY);
      logout({returnTo: window.location.origin});
    }
  });

  useEffect(() => {
    getToken();
  }, [getToken, isAuthenticated]);

  if (isLoading) {
    return handleLoadingApp;
  }

  return isAuthenticated && <Home isAuthenticated={isAuthenticated} />;
};

const handleLoadingApp = () => {
  return <div>Aguarde um momento, carregando a aplicação...</div>;
};

export default withAuthenticationRequired(Auth0, {
  onRedirecting: handleLoadingApp,
});
