import React from 'react';
import PropTypes from 'prop-types';

import GraphiQL from 'graphiql';// eslint-disable-line

import 'graphiql/graphiql.css';
import './style.css';

class Playground extends React.Component {
  render() {
    const {isAuthenticated, onHandleGraphQLFetcher} = this.props;

    if (!isAuthenticated) {
      return <p id='redirecting-message'>Aguarde um momento...</p>;
    }

    return (
      <div id='graphql-playground-container'
        style={{height: `${window.innerHeight - 70}px`}}>
        <GraphiQL fetcher={onHandleGraphQLFetcher} />
      </div>
    );
  }

  static get propTypes() {
    return {
      isAuthenticated: PropTypes.any,
      onHandleGraphQLFetcher: PropTypes.func,
    };
  }
}

export default Playground;
