import React from 'react';
import {useAuth0} from '@auth0/auth0-react';

import './style.css';


const LogoutButton = () => {
  const {logout} = useAuth0();
  return (
    <button
      id='user-logout'
      onClick={() => logout({logoutParams: {returnTo: window.location.origin}})}
    >
      Sair
    </button>
  );
};


const DisplayUser = () => {
  const {user, isAuthenticated, isLoading} = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <div id='user-info-container'>
        <div id='user-info'>
          <div>
            <span>{user.name}</span>
            <span>{user.email}</span>
          </div>
        </div>
        <div>
          <LogoutButton />
        </div>
      </div>
    )
  );
};


const Header = () => {
  const {isLoading} = useAuth0();

  if (isLoading) {
    return <div>Carregando a página ...</div>;
  }

  return (
    <header id='menu-bar'>
      <div id='logo-container'>
        <img alt='Yandeh' src='img/yandeh.png' />
      </div>
      <DisplayUser />
    </header>
  );
};


export default Header;

